import axios from 'axios'
import qs from 'qs'
import {getToken, remove, getUserId, getUserName} from "@/utils/authUtils";
// import store from "@/store";
import { Toast } from 'vant';
import router from "@/router/router";

const VISIT_TYPE = 'KEEP_H5'
const API = "/api_h5/";
// let loading = null;
// 使用自定义配置新建一个 axios 实例
const instance = axios.create({
    headers: {'Content-Type': 'application/json;charset=utf-8'},
    // 基础的请求地址
    // baseURL: 'https://api.chuangfushuzi.com' + API,
    baseURL: 'https://prod.chuangfushuzi.com' + API,
    // baseURL: 'http://localhost:6060' + API,
    // 设置超时时间 5s
    timeout: 60000,
});

// axios拦截器
instance.interceptors.request.use(config => {
    // Loading.service(loading)
    // loading = Loading.service({
    //     text: '加载中...',
    //     background: 'rgba(255, 255, 255, 0.7)'
    // });

    //可在此显示加载图标
    //可在此做拦截登录
    return config;
}, error => {
    console.log("error:" + error)
})

//响应拦截
instance.interceptors.response.use(res => {
    let data = res.data;
    switch (data.head.code) {
        case '10001':
            Toast.fail(data.body.msg);

            return null;
        case '10002':
            remove();
            Toast.fail('账号已过期，请重新登录')
            router.replace("/login")
            return null;
    }
    //请求成功后的拦截
    return data.body;
}, error => {
    Toast.fail('系统出现异常，请联系管理员')
    //请求失败的拦截
})

//格式化参数
export function formatParams(data) {
    let token = getToken();
    let userId = getUserId();
    let userName = getUserName();
    data = {
        body: {...data},
        head: {visitType: VISIT_TYPE, token: token, userId: userId, userName: userName}
    }
    return data;
}

//请求方法
const request = {
    post(url, params) {
        return instance.post(url, formatParams(params), {
            transformRequest: [(params) => {
                return JSON.stringify(params)
            }]
        })
    },
    put(url, params) {
        return instance.put(url, params, {
            transformRequest: [(params) => {
                return JSON.stringify(params)
            }],
            headers: {
                'Content-Type': 'application/json'
            }
        })
    },
    //parm =>  {id:10}
    // http://localhost:8089/api/user?id=10
    get(url, params) {
        return instance.get(url, {
            params: formatParams(params),
            paramsSerializer: (params) => {
                return qs.stringify(params)
            }
        })
    },
    //parm =>  {id:10}
    // http://localhost:8089/api/user/10
    delete(url, params) {
        let _params
        if (Object.is(params, undefined || null)) {
            _params = ''
        } else {
            _params = '/'
            for (const key in params) {
                // eslint-disable-next-line no-prototype-builtins
                if (params.hasOwnProperty(key) && params[key] !== null && params[key] !== '') {
                    _params += `${params[key]}/`
                }
            }
            //去掉参数最后一位?
            _params = _params.substr(0, _params.length - 1)
        }
        if (_params) {
            return instance.delete(`${url}${_params}`).catch(err => {
                return Promise.reject(err)
            })
        } else {
            return instance.delete(url).catch(err => {
                return Promise.reject(err)
            })
        }
    },
    upload(url, params) {
        return instance.post(url, params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    },
    login(url, params) {
        return instance.post(url, params, {
            transformRequest: [(params) => {
                return qs.stringify(params)
            }],
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    },
    //获取验证
    getImage(url) {
        return instance.post(url, null, {
            responseType: 'arraybuffer'
        })
    }
}
export default request;
